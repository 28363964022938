import React from 'react';
import styles from '../../styles/components/private-lineup.module.css'; // Adjust the path
import { useAuth0 } from "@auth0/auth0-react";

export const PrivateLineupCard = ({ 
    concert,
    handleDelete 
  }) => {

    const { user } = useAuth0();
    const isOwner = user.sub === concert.user_id; 

    return (
        <div key={concert._id} className={styles.eventCard}>
        <h3 className={styles.artistName}>{concert?.concertName}</h3>
        {isOwner ? (
            <button className={styles.deleteButton}
            onClick={() => handleDelete(concert._id)}
            >
              <p>Delete</p>
            </button>
        ) : (
          null
        )}
      </div>
  )};
